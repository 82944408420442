<template>
  <v-combobox
    v-model="selection"
    :items="tags"
    :loading="loading"
    :search-input.sync="search"
    label="Tags"
    item-text="name.en"
    item-value="id"
    :delimiters="[',']"
    hide-selected
    cache-items
    dense
    multiple
    chips
    deletable-chips
    small-chips
    @change="$emit('update:value', selection)"
  >
    <template #prepend>
      <v-icon size="22">
        fal fa-tags mt-1
      </v-icon>
    </template>
  </v-combobox>
</template>
<script>
export default {
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    type: {
      type: String,
      default: null,
    },
  },
  data () {
    return {
      loading: false,
      search: null,
      selection: this.value,
      tags: [],
    }
  },
  watch: {
    search () {
      this.fetchTags()
    },
  },
  created () {
    this.fetchTags()
  },
  methods: {
    fetchTags () {
      this.loading = true
      const promise = this.axios.get('admin/tags', {
        params:
          {
            count: 20,
            search: this.search,
            type: this.type,
          },
      })
      return promise.then((response) => {
        this.tags = response.data.data
        this.loading = false
      }).catch(error => {
        this.$toast.error(error)
      })
    },
  },
}
</script>
